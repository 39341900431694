import request from '@/utils/request'

export const saveOrUpdate: any = (data: any) => {
    return request({
        url: `/system/renew`,
        method: 'post',
        data
    })
}

export const pageListRenew: any = (data: any) => {
    return request({
        url: `/system/renew/pageListRenew`,
        method: 'post',
        data
    })
}

export const toggleShelves: any = (id: string) => {
    return request({
        url: `/system/renew/toggleShelves/${id}`,
        method: 'put',
    })
}

export const getRenewDetails: any = (id: string) => {
    return request({
        url: `/system/renew/getRenewDetails/${id}`,
        method: 'get',
    })
}